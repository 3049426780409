import React from 'react';
import { Link, graphql } from "gatsby";
import Layout from '../components/layout';
import Img from 'gatsby-image';
import './pages.css';

function ProjectsPage(props) {
  const images = props.data.allFile.edges.map(image => {
    return image.node;

  })
  const mkdata = props.data.allMarkdownRemark.group;
  let maya = mkdata.filter(tag => tag.fieldValue === 'maya');

  return (

    <Layout>
      <div className="projects">
        <h1>Current Projects</h1>
        <Img className="thumb" fixed={images[4].childImageSharp.fixed} />
        <h2>
        Maya of the Golden Moon
        <div>{
          maya.map((tag, i) => (
                <div key ={i}>
                 <Link to={`/${tag.fieldValue}`} >
                            {"Chapters"} 
                      </Link>   
                </div>  
            ))
        }
        </div>
        </h2>
        <p>One day, Maya Mizuki, a fourth grader at Twilight Elementary, suddenly finds herself lost in another world – Shadowin. She was called there for a reason: to become an Elmanio, a mage who controls the Elma spirits, and the Savior of this world engulfed by night. Enrolling in the Royal Academy, Maya meets new friends Karura and Miroku, and studies the Elma to become an Elmanio. However, one day in the forest, she falls into a hole in a great stone, and encounters a mysterious object.</p>
        <p>A school age fantasy based on the JRPG series Popolocrois. </p>
       </div>
    </Layout>
    
  )
}


export default ProjectsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    allFile (filter: { sourceInstanceName: { eq: "images" } }){
    edges{
      node{
        relativePath
        childImageSharp{
          fixed(width: 90, height: 135) {
              src
              ...GatsbyImageSharpFixed
            }
        }
      }
    }
  } 
  }
`